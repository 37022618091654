import { ContentfulMercadosALaApertura } from '../../../types/graphql-types';
import parseDate from '../../utils/parseDate';
import { MercadosALaApertura as MercadosALaAperturaProps } from '../../models/MercadosALaApertura';

export const useMercadosALaAperturaPost = (
  data: ContentfulMercadosALaApertura
): MercadosALaAperturaProps => {
  return {
    id: data.id,
    title: data.titulo ? data.titulo : 'Título de Mercados a la Apertura',
    description: data.resumen ? data.resumen : 'Resumen de publicación',
    category: 'Dirección',
    content: 'Mercados a la Apertura',
    categorySlug: '',
    contentSlug: 'mercados-a-la-apertura',
    date: data.createdAt ? parseDate(data.createdAt) : 'Fecha de publicación',
    fluid: data.fotoDePortada,
    fluidDescription: data.fotoDePortada?.description,
    author: data.autor?.nombre ? data.autor?.nombre : 'Autor',
    authorObject: {
      id: data.autor?.id,
      nombre: data.autor?.nombre,
      twitter: data.autor?.twitter,
      bio: data.autor?.bio,
      avatar: data.autor?.foto,
    },
    tags: data.tags
      ? data.tags.map((tag: unknown) => {
          return {
            nombre: tag.nombre,
            slug: tag.slug,
          };
        })
      : null,
    mercadosEnElMundo: data.mercadosEnElMundo,
    tablaIndicadores: data.tablaIndicadores,
    expectativaDeApertura: data.expecativaDeApertura,
    imagenApertura: data.imagenApertura,
    descriptionImagenApertura: data.imagenApertura?.description,
    noticias: data.noticias,
    tituloNoticia1: data.tituloNoticia1,
    textoNoticia1: data.textoNoticia1,
    tituloNoticia2: data.tituloNoticia2,
    textoNoticia2: data.textoNoticia2,
    tituloNoticia3: data.tituloNoticia3,
    textoNoticia3: data.textoNoticia3,
    tecnicoOpinaMexico: data.elTecnicoOpinaMexico,
    tecnicoOpinaEUA: data.elTecnicoOpinaEstadosUnidos,
    tituloTecnicoOpinaOtro: data.tituloElTecnicoOpinaOtro,
    textoTecnicoOpinaOtro: data.elTecnicoOpinaOtro,
    mercadoDeCambiosSpot: data.mercadoDeCambiosSpot,
    mercadoDeCambiosMexico: data.mercadoDeCambiosMexico,
    mercadoDeCambiosEUA: data.mercadoDeCambiosEstadosUnidos,
    lasMesasOpinanCapitales: data.lasMesasOpinanCapitales,
    lasMesasOpinanDivisas: data.lasMesasOpinanDivisas,
    imagenLasMesasOpinan: data.imagenLasMesasOpinan,
    descriptionImagenLasMesasOpinan: data.imagenLasMesasOpinan?.description,
    tablaAgendaEconomica: data.tablaAgendaEconomica,
    tablaSalidaNuevosValores: data.tablaSalidaNuevosValores,
    tablaTasasDeInteres: data.tablaTasasDeInteres,
    tables: data.tables,
    autorComentarioDiario: {
      id: data.comentarioDiario?.autor?.nombre,
      nombre: data.comentarioDiario?.autor?.nombre,
      twitter: data.comentarioDiario?.autor?.twitter,
      bio: data.comentarioDiario?.autor?.bio,
      avatar: data.comentarioDiario?.autor?.foto,
      puesto: data.comentarioDiario?.autor?.puesto,
    },
    tituloComentarioDiario: data.comentarioDiario?.titulo,
    textoComentarioDiario: data.comentarioDiario?.texto,
  };
};
