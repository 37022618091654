import React from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from '@material-ui/core';
import type { Table as TableProps } from '../models/MercadosAperturaTables';
import type { Row as RowProps } from '../models/MercadosAperturaTables';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import US from '../assets/icons/us.svg';
import { useMediaQuery } from '@material-ui/core';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& hr': {
        borderColor: '#959390',
      },
      '& *': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
      },
    },
    guiaColores: {
      width: "20%",
      '& div': {
        // display: 'flex',
        // alignItems: 'center',
        // marginTop: 10,
      },
      '& .circleBig': {
        width: 15,
        height: 15,
        display: 'inline-table',
        borderRadius: 15,
        marginRight: 10,
        background: '#C21212',
        '&-green': {
          background: '#0C5300',
        },
      },
    },
    subtitle: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontSize: 16,
      maxWidth: 280,
      margin: '0',
      fontWeight: 'bold',
    },
    tableTitle: {
      fontSize: "20px",
      fontFamily: "Gotham Book",
      color: '#262626',
      marginTop: 20,
      marginBottom: 10,
      display: "flex",
      alignItems: "center",
    },
    tableHead: {
      padding: '2px 2px 2px 2px',
      background: '#959390',
      color: '#fff',
    },
    table: {
      width: '100%',
      '& .MuiTableCell-sizeSmall': {
        padding: '2px 2px 2px 2px',
        fontSize: 14,
      },
      '& .circle': {
        width: 15,
        display: 'block',
        height: 15,
        borderRadius: 10,
        margin: '0 auto',
        '&-green': {
          background: '#1CCF24',
        },

        '&-orange': {
          background: '#FF6600',
        },
        '&-yellow': {
          background: '#FFD600',
        },
        '&-red': {
          background: 'red',
        },

        '&-green2': {
          background: 'green',
        },
      },
      '& th.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeSmall': {
        // background: '#F9F9F9',
        fontSize: 14,
      },
    },
    row:{
      background: "#F6F6F6",
      borderRadius: "20px",
      marginBottom: "19px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // textAlign: "center",
      padding: "3px 12px",
      fontFamily: "Gotham Book"
    },
    rowHead:{
      "& > div:first-child": {
        width: "40%",
        textAlign: "left",
        '@media (max-width: 576px)': {
          width: '34%',
        },
      },
      "& > div":{
        width: "20%",
        textAlign: "right",
        '@media (max-width: 576px)': {
          width: '22%',
        },
      }

    }
  })
);


function formatNumber(floatValue = 0, decimals = 0, multiplier = 1) {
  let floatMultiplied = floatValue * multiplier;
  let stringFloat = floatMultiplied + "";
  let arraySplitFloat = stringFloat.split(",");
  let decimalsValue = "0";
  if (arraySplitFloat.length > 1) {
      decimalsValue = arraySplitFloat[1].slice(0, decimals);
  }
  let integerValue = arraySplitFloat[0];
  let arrayFullStringValue = [integerValue, decimalsValue];
  let FullStringValue = arrayFullStringValue.join(".")
  let floatFullValue = parseFloat(FullStringValue) + "";
  let formatFloatFullValue = new Intl.NumberFormat('es-MX', { minimumFractionDigits: decimals }).format(floatFullValue);
  return formatFloatFullValue;

}
const TableText = ({
  type,
  subtitle,
  title,
  rows,
  columns,
}: TableProps): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.root}>
      <>
        <div className={classes.tableTitle}>{title} <ExpandMoreIcon style={{marginLeft: "16px", color: "#FF5000", fontSize: 24}}/></div>
        {subtitle && <div style={{ marginBottom: 10 }}>{subtitle}</div>}

        {/* <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column: string) => (
                    <TableCell
                      style={{
                        color: '#000',
                        lineHeight: 1.4,
                        padding: 5,
                        fontSize: 14,
                        fontWeight: 600,
                        fontFamily: "Gotham book"
                      }}
                    >
                      {column}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row: RowProps) => (
                <TableRow key={row.column0}>
                  <TableCell component="th" scope="row">
                    <strong
                      style={{
                        fontSize: '.9em',
                      }}
                    >
                      {row.column0}
                    </strong>
                  </TableCell>
                  <TableCell
                    style={{
                      color: 'grey',
                      fontSize: '.8em',
                    }}
                    align="center"
                  >
                    {row.column1}
                  </TableCell>
                  <TableCell
                    style={
                      typeof row.column3 !== 'string'
                        ? {
                            color: row.column3.toString().includes('-')
                              ? '#ed1d25'
                              : '#04bf12',
                            fontSize: '.8em',
                          }
                        : { fontSize: '.8em' }
                    }
                    align="center"
                    className={classes.guiaColores}
                  >
                    <strong
                      style={{
                        color: row.column2.toString().includes('-')
                          ? '#ed1d25'
                          : '#04bf12',
                        fontSize: '1em',
                      }}
                    >
                      {' '}
                      {row.column2}
                    </strong>
                  </TableCell>
                  <TableCell align="center" className={classes.guiaColores}>
                    <strong
                      style={
                        row.column3 !== 'US' && row.column3 !== 'MX'
                          ? {
                              color: row.column3.toString().includes('-')
                                ? '#ed1d25'
                                : '#04bf12',
                              fontSize: '.8em',
                            }
                          : { fontSize: '.8em' }
                      }
                    >
                      {row.column3 === 'MX'
                        ? '🇲🇽'
                        : row.column3 === 'US'
                        ? '🇺🇸'
                        : row.column3}
                    </strong>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}

        <div>

          <div style={{
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            marginBottom: "10px"
          }}
          className={classes.rowHead}
          >
            {columns &&
                  columns.map((column: string) => (
                    <div
                      style={{
                        color: '#000',
                        // lineHeight: 1.4,
                        // padding: "0 5px",
                        fontSize: mobile ? 10 : 14,
                        fontWeight: 600,
                        fontFamily: "Gotham book",
                      }}
                    >
                      {column}
                    </div>
                  ))}
          </div>

          <div>
            {rows.map((row: RowProps) => (

                <div key={row.column0} className={classes.row}>

                  <div style={{ width:mobile ?"34%":"40%", textAlign: "left" }}>
                    <div style={{ fontSize: mobile ?'12px':'14px', fontFamily: "Gotham book" }} >
                      {row.column0}
                    </div>
                  </div>

                  <div style={{ fontSize: mobile ?'12px':'14px', fontFamily: "Gotham book", width:mobile?"22%":"20%", textAlign: "right" }}>
                    {row.column1}
                    {/* {formatNumber(row.column1, 2)} */}
                  </div>

                  <div
                    style={
                      typeof row.column3 !== 'string'
                        ? {
                            color: row.column3.toString().includes('-')
                              ? '#ed1d25'
                              : '#04bf12',
                            fontSize:  mobile ?'12px':'14px',
                          }
                        : { fontSize:  mobile ?'12px':'14px'}
                      
                    }
                    
                    className={classes.guiaColores}
                  >
                    <div
                      style={{
                        color: row.column2.toString().includes('-')
                          ? '#ed1d25'
                          : '#04bf12',
                        fontSize:  mobile ?'12px':'14px',
                        fontFamily: "Gotham Book",
                        textAlign: "right",
                        display:"flex",
                        alignItems:"center",
                        justifyContent: "flex-end"
                      }}
                    >
                      {' '}
                      { 
                        row.column2.toString() !== "" ?
                          row.column2.toString().includes('-')
                          ?
                          <ExpandMoreIcon style={{fontsize:mobile?18:22, marginRight:mobile?0:5}}/>
                          :
                          <ExpandLessIcon style={{fontsize:mobile?18:22, marginRight:mobile?0:5}}/>
                        :
                        ""
                      }
                      {row.column2}
                      {/* {console.log("[TABLE COLUM2]",row.column2)} */}
                    </div>
                  </div>



                  <div className={classes.guiaColores}>
                    <div
                      style={
                        row.column3 !== 'US' && row.column3 !== 'MX'
                          ? {
                              color: row.column3.toString().includes('-')
                                ? '#ed1d25'
                                : '#04bf12',
                                fontSize:  mobile ?'12px':'14px',
                                fontFamily: "Gotham Book",
                                textAlign:"right",
                                display:"flex",
                                alignItems:"center",
                                justifyContent: "flex-end"
                            }
                          : { 
                              fontSize:  mobile ?'12px':'14px',
                              display:"flex",
                              alignItems:"center",
                              justifyContent: "flex-end" 
                            }
                      }
                    >
                      { 
                        row.column3 !== 'MX' && row.column3 !== 'US' ?
                        row.column3.toString().includes('-')
                        ?
                        <ExpandMoreIcon style={{fontsize:mobile?18:22, marginRight:mobile?0:5}}/>
                        :
                        <ExpandLessIcon style={{fontsize:mobile?18:22, marginRight:mobile?0:5}}/>
                        :
                        ""
                      }
                      { 
                        row.column3 === 'MX'
                        ? '🇲🇽'
                        : row.column3 === 'US'
                        ? <img src={US}/>
                        : row.column3 
                      }
                    </div>
                  </div>
                </div>
              ))}
          </div>

        </div>
      </>
    </div>
  );
};

TableText.defaultProps = {
  type: 'exit-new-values',
  title: '',
  subtitle: '',
  recommendedActions: true,
};

export default TableText;
