import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Container, useMediaQuery } from '@material-ui/core';

import { renderRichText } from "gatsby-source-contentful/rich-text"

import { ContentfulMercadosALaApertura } from '../../types/graphql-types';
import { useMercadosALaAperturaPost } from '../operations/queries/mercadosALaAperturaPost';
import PostLayout from '../components/PostLayout';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
import { MercadosALaApertura as MercadosALaAperturaProps } from '../models/MercadosALaApertura';
import IconTitle from '../components/IconTitle';
import { useTheme } from '@material-ui/core/styles';
import ImageAndDesc from '../components/ImageAndDesc';

// SVG assets
import USA from '../assets/icons/icon-USA.svg';
import Mexico from '../assets/icons/icon-Mexico.svg';
import Index from '../assets/icons/icon-Index.svg';
import World from '../assets/icons/icon-World.svg';
import Mesas from '../assets/icons/icon-Mesas.svg';
import Mercados from '../assets/icons/icon-Mercados.svg';
import Noticia from '../assets/icons/icon-Newspaper.svg';
import OtroMercado from '../assets/icons/icon-OtherMarkets.svg';
import Sunrise from '../assets/icons/icon-Sunrise.svg';

import Airtable from '../components/Airtable';
import options from '../utils/getRichTextOptions';
import StickyBox from 'react-sticky-box';
import { MercadosAperturaTables as MercadosAperturaTablesProps } from '../models/MercadosAperturaTables';
import { Table as TableProps } from '../models/MercadosAperturaTables';
import TableText from '../components/TableText';
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';
import GdprCookies from '../components/GdprCookies';

interface Props {
  data: {
    contentfulMercadosALaApertura: ContentfulMercadosALaApertura;
    allMongodbDireccionMercadosapertura: {
      edges: { node: MercadosAperturaTablesProps };
    };
  };
}

export const Head = ({data}) => {
  const post: MercadosALaAperturaProps = useMercadosALaAperturaPost(
    data.contentfulMercadosALaApertura
  );
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function MercadosALaApertura({ data }: Props): JSX.Element {
  const post: MercadosALaAperturaProps = useMercadosALaAperturaPost(
    data.contentfulMercadosALaApertura
  );
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const tablesV3: any = useMercadosAperturaTables(
  //   data.allMongodbDireccionMercadosapertura.edges[0].node
  // );

  const tablesV3: any = data?.allMongodbDireccionMercadosapertura?.edges[0]?.node?.tables;

  // console.log(tablesV3);

  const postPreview = (): PostPreviewProps => {
    const {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      fluidDescription,
      author,
      authorObject,
      content,
      tags,
    } = post;
    return {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      fluidDescription,
      author,
      authorObject,
      content,
      tags,
    };
  };
  const tablasLMAAnteriores: any = [];

  tablasLMAAnteriores.push(post?.tablaAgendaEconomica);
  tablasLMAAnteriores.push(post?.tablaSalidaNuevosValores);
  tablasLMAAnteriores.push(post?.tablaTasasDeInteres);

  const tables = post?.tables ? post?.tables : tablasLMAAnteriores;

  // console.log(tables);

// console.log(post)
  const isClient = useIsClient();

  if(isClient){

    return (
      <>
        <PostLayout post={postPreview()}>
          <>
            <>
              <h2>{post.description}</h2>
            </>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={
                  tablesV3
                    ? mobile
                      ? { zIndex: 0, width: '100%' }
                      // : { width: 250, margin: 20 }
                      // : { margin: 20 }
                      : {}
                    : mobile
                    ? { zIndex: 0, width: '100%' }
                    : { width: '350px' }
                }
              >
                {tablesV3
                  ? tablesV3.map((table: TableProps) => {
                      return (
                        <TableText
                          title={table.title}
                          type={table.type}
                          rows={table.rows}
                          columns={table.columns}
                        />
                      );
                    })
                  : tables &&
                    tables.map((tabla: TableProps) => {
                      if (tabla?.tipo === 'tarjeta' || tabla?.tipo === null) {
                        //tarjeta
                        return (
                          <Container>
                            <IconTitle icon={Index} title={tabla.titulo}>
                              <Airtable
                                src={tabla.tabla}
                                description={tabla.descripcion}
                                card={true}
                              />
                            </IconTitle>
                          </Container>
                        );
                      }
                    })}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                style={mobile ? { zIndex: 0, background: 'white' } : { paddingLeft:"49px"}}
              >

                {post.mercadosEnElMundo && (
                  <IconTitle title="Mercados en el Mundo" icon={Mercados}>
                    {renderRichText(post.mercadosEnElMundo, options)}
                  </IconTitle>
                )}
                {post.expectativaDeApertura && (
                  <IconTitle title="Expectativa de Apertura" icon={Sunrise}>
                    {renderRichText(post.expectativaDeApertura, options)}
                  </IconTitle>
                )}
                {post.imagenApertura && (
                  <ImageAndDesc
                    fluid={post.imagenApertura}
                    description={post.descriptionImagenApertura}
                  />
                )}
                {post.noticias && (
                  <IconTitle title="Noticias y eventos relevantes" icon={Noticia}>
                    {renderRichText(post.noticias, options)}
                  </IconTitle>
                )}
                {post.tecnicoOpinaMexico && (
                  <IconTitle title="Técnico opina: México" icon={Mexico}>
                    {renderRichText(post.tecnicoOpinaMexico, options)}
                  </IconTitle>
                )}
                {post.tecnicoOpinaEUA && (
                  <IconTitle title="Técnico opina: Estados Unidos" icon={USA}>
                    {renderRichText(post.tecnicoOpinaEUA, options)}
                  </IconTitle>
                )}
  
                {post.tituloTecnicoOpinaOtro && (
                  <IconTitle
                    title={`Técnico opina: ${post.tituloTecnicoOpinaOtro}`}
                    icon={World}
                  >
                    {post.textoTecnicoOpinaOtro &&
                      renderRichText(post.textoTecnicoOpinaOtro, options)
                    }
                  </IconTitle>
                )}
                {post.mercadoDeCambiosSpot && (
                  <IconTitle title="Mercado de cambios Spot" icon={OtroMercado}>
                    {renderRichText(post.mercadoDeCambiosSpot, options)}
                  </IconTitle>
                )}
                {post.mercadoDeCambiosMexico && (
                  <IconTitle title="Mercado de cambios México" icon={Mexico}>
                    {renderRichText(post.mercadoDeCambiosMexico, options)}
                  </IconTitle>
                )}
                {post.mercadoDeCambiosEUA && (
                  <IconTitle title="Mercado de cambios Estados Unidos" icon={USA}>
                    {renderRichText(post.mercadoDeCambiosEUA, options)}
                  </IconTitle>
                )}
                {post.imagenLasMesasOpinan && (
                  <ImageAndDesc
                    fluid={post.imagenLasMesasOpinan}
                    description={post.descriptionImagenLasMesasOpinan}
                  />
                )}
                {post.lasMesasOpinanCapitales && (
                  <IconTitle title="Las Mesas opinan: Capitales" icon={Mesas}>
                    {renderRichText(post.lasMesasOpinanCapitales, options)}
                  </IconTitle>
                )}
                {post.lasMesasOpinanDivisas && (
                  <IconTitle title="Las Mesas opinan: Divisas" icon={Mesas}>
                    {renderRichText(post.lasMesasOpinanDivisas, options)}
                  </IconTitle>
                )}
  
                {tables &&
                  tables.map((tabla: TableProps) => {
                    if (tabla?.tipo === '50%') {
                      return (
                        <>
                          <IconTitle icon={Index} title={tabla.titulo}>
                            <Airtable
                              src={tabla.tabla}
                              description={tabla.descripcion}
                              card={false}
                            />
                          </IconTitle>
                        </>
                      );
                    }
                  })}
              </Grid>
            </Grid>
            <>
              {tables &&
                tables.map((tabla: TableProps) => {
                  if (tabla?.tipo === '100%') {
                    return (
                      <>
                        <Grid item xs={12} sm={12} md={6}></Grid>
                        <IconTitle icon={Index} title={tabla.titulo}>
                          <Airtable
                            src={tabla.tabla}
                            description={tabla.descripcion}
                            card={false}
                          />
                        </IconTitle>
                      </>
                    );
                  }
                })}
            </>
          </>
        </PostLayout>
        <GdprCookies />

      </>
    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulMercadosALaApertura(slug: { eq: $slug }) {
      ...mercadosALaApertura
    }
    allMongodbDireccionMercadosapertura(
      filter: { slug: { eq: $slug }}
    ) {
      edges {
        node {
          ...MercadosAperturaTable
        }
      }
    }
  }
`;