const getLinkWithEmbed = (src: string): string => {
  let newSrc: string = '';
  if (!src.includes('/embed/')) {
    const res: string[] = src.split('/');
    const placeEmbed: number = res.length - 2;

    for (const i in res) {
      const iNum: number = +i;
      if (iNum == placeEmbed) {
        newSrc += res[iNum];
        newSrc += '/';
        newSrc += 'embed';
        newSrc += '/';
      } else if (iNum == res.length - 1) {
        newSrc += res[iNum];
      } else {
        newSrc += res[iNum];
        newSrc += '/';
      }
    } // for res
  } // if embed
  else {
    newSrc = src;
  }
  return newSrc;
};

export default getLinkWithEmbed;
