import React from 'react';

import getLinkWithEmbed from '../utils/getLinkWithEmbed';



interface Props {

card?: boolean;

src: string;

height?: string;

width?: string;

backgroundColor?: string;

description: string | null | undefined;

}



const Airtable = ({

card,

src,

height,

width,

backgroundColor,

description,

}: Props): JSX.Element => {

let cardOption = '';



if (card) {

cardOption = '&layout=card';

}



return (

<>

<iframe

style={{ marginBottom: 0 }}

title="Recomendaciones"

src={`${getLinkWithEmbed(

src

)}?backgroundColor=${backgroundColor}${cardOption}`}

frameBorder="0"

height={height}

width={width}

/>

{description && (

<p style={{ fontSize: 12, fontFamily: 'Roboto', color: 'grey' }}>

Fuente: {description}

</p>

)}

</>

);

};



export default Airtable;



Airtable.defaultProps = {

style: {},

card: false,

height: '350',

width: '100%',

backgroundColor: 'orange',

};



